import React, { useState } from 'react';
import { array, arrayOf, bool, func, shape, string, oneOf, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { useConfiguration } from '../../context/configurationContext';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { FormattedMessage, intlShape, useIntl } from '../../util/reactIntl';
import {
  LISTING_STATE_PENDING_APPROVAL,
  LISTING_STATE_CLOSED,
  SCHEMA_TYPE_MULTI_ENUM,
  SCHEMA_TYPE_TEXT,
  propTypes,
} from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
} from '../../util/urlHelpers';
import { convertMoneyToNumber } from '../../util/currency';
import {
  ensureListing,
  ensureOwnListing,
  ensureUser,
  getPageURL,
  userDisplayNameAsString,
} from '../../util/data';
import { richText } from '../../util/richText';
import {
  isBookingProcess,
  isPurchaseProcess,
  resolveLatestProcessName,
} from '../../transactions/transaction';

import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/ui.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';

import {
  H4,
  Page,
  NamedLink,
  NamedRedirect,
  OrderPanel,
  LayoutSingleColumn,
  SocialShare,
  Modal,
  Button,
} from '../../components';

import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FooterContainer from '../FooterContainer/FooterContainer';
import NotFoundPage from '../NotFoundPage/NotFoundPage';

import {
  sendInquiry,
  setInitialValues,
  fetchTimeSlots,
  fetchTransactionLineItems,
  waitlistSendEmail,
  showListing,
} from './ListingPage.duck';

import {
  LoadingPage,
  ErrorPage,
  priceData,
  listingImages,
  handleContactUser,
  handleSubmitInquiry,
  handleSubmit,
} from './ListingPage.shared';
import ActionBarMaybe from './ActionBarMaybe';
import SectionTextMaybe from './SectionTextMaybe';
import SectionDetailsMaybe from './SectionDetailsMaybe';
import SectionMultiEnumMaybe from './SectionMultiEnumMaybe';
import SectionReviews from './SectionReviews';
import SectionAuthorMaybe from './SectionAuthorMaybe';
import SectionMapMaybe from './SectionMapMaybe';
import SectionGallery from './SectionGallery';

import css from './ListingPage.module.css';
import IconCard from '../../components/SavedCardDetails/IconCard/IconCard.js';
import EmailInquiryForm from './EmailInquiry/EmailInquiryForm.js';
import { emailInquiry } from '../../util/api.js';
import { courseDressCode, courseLevels, courseRequirements, courseType } from '../../config/configListing';
import emailInquiryIcon from '../../assets/email-inquiry.png';

const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;

const { UUID } = sdkTypes;

export const ListingPageComponent = props => {
  const [inquiryModalOpen, setInquiryModalOpen] = useState(
    props.inquiryModalOpenForListingId === props.params.id
  );

  const {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    intl,
    onManageDisableScrolling,
    params: rawParams,
    location,
    scrollingDisabled,
    showListingError,
    reviews,
    fetchReviewsError,
    sendInquiryInProgress,
    sendInquiryError,
    monthlyTimeSlots,
    onFetchTimeSlots,
    onFetchTransactionLineItems,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    history,
    callSetInitialValues,
    onSendInquiry,
    onwaitlistSendEmail,
    onInitializeCardPaymentData,
    config,
    onshowListing,
    routeConfiguration,
    totalTimeSlots
  } = props;

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [waitlistmodalIsOpen, setWaitlistModalIsOpen] = useState(false);
  const [isOpenSocialModel, setIsOpenSocialModel] = useState(false);
  const PageUrl = getPageURL();
  const currentuseremail = currentUser?.attributes?.email;

  const handleClickEmailInquiry = (isAuthenticated) => {
    if (!isAuthenticated) {
      window.location.href = process.env.REACT_APP_MARKETPLACE_ROOT_URL + '/signup';
    } else {
      setModalIsOpen(true);
    }
  };
  const closeModal = (values) => {
    const message = values?.message ? values?.message : null
    { message && emailInquiry({ inquiry: message, email: currentuseremail }) }
    setModalIsOpen(false);
  };

  const listingConfig = config.listing;
  const listingId = new UUID(rawParams.id);
  const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
  const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
  const currentListing =
    isPendingApprovalVariant || isDraftVariant
      ? ensureOwnListing(getOwnListing(listingId))
      : ensureListing(getListing(listingId));

  const listingSlug = rawParams.slug || createSlug(currentListing.attributes.title || '');
  const params = { slug: listingSlug, ...rawParams };

  const isApproved =
    currentListing.id && currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;
  const pendingIsApproved = isPendingApprovalVariant && isApproved;
  // If a /pending-approval URL is shared, the UI requires
  // authentication and attempts to fetch the listing from own
  // listings. This will fail with 403 Forbidden if the author is
  // another user. We use this information to try to fetch the
  // public listing.
  const pendingOtherUsersListing =
    (isPendingApprovalVariant || isDraftVariant) &&
    showListingError &&
    showListingError.status === 403;
  const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;

  if (shouldShowPublicListingPage) {
    return <NamedRedirect name="ListingPage" params={params} search={location.search} />;
  }

  const topbar = <TopbarContainer />;

  if (showListingError && showListingError.status === 404) {
    // 404 listing not found
    return <NotFoundPage />;
  } else if (showListingError) {
    // Other error in fetching listing
    return <ErrorPage topbar={topbar} scrollingDisabled={scrollingDisabled} intl={intl} />;
  } else if (!currentListing.id) {
    // Still loading the listing
    return <LoadingPage topbar={topbar} scrollingDisabled={scrollingDisabled} intl={intl} />;
  }

  const {
    description = '',
    geolocation = null,
    price = null,
    title = '',
    publicData = {},
    metadata = {},
  } = currentListing.attributes;

  const listingPathParamType = isDraftVariant
    ? LISTING_PAGE_PARAM_TYPE_DRAFT
    : LISTING_PAGE_PARAM_TYPE_EDIT;
  const listingType = publicData?.listingType;
  const isLocation = currentListing?.attributes?.geolocation && listingType === "course-listing";

  const listingTab = isDraftVariant ? 'photos' : (listingType === "course-listing" && !isLocation) ? 'details' : (listingType === "course-listing" && isLocation) ? "location" : 'shop-details';
  // const listingTab = isDraftVariant ? 'photos' : listingType === "course-listing" ? 'details' : 'shop-details';

  const richTitle = (
    <span>
      {richText(title, {
        longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
        longWordClass: css.longWord,
      })}
    </span>
  );
  const dressCodeKey = publicData?.courseDressCode;
  const courseLevelsKey = publicData?.courseLevels;
  const courseRequirementsKey = publicData?.courseRequirements;
  const courseTypeKey = publicData?.courseType;
  const listItemsLabels = publicData?.listItems;
  const FAQsArr = publicData?.FAQs;
  const dressCodeLabels = courseDressCode.filter(item => item.key === dressCodeKey).map(item => item.label);
  const courseLevelsLabels = courseLevels.filter(item => item.key === courseLevelsKey).map(item => item.label);
  const courseRequirementsLabels = courseRequirements.filter(item => item.key === courseRequirementsKey).map(item => item.label);
  const courseTypeLabels = courseType.filter(item => item.key === courseTypeKey).map(item => item.label);
  const authorAvailable = currentListing && currentListing.author;
  const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
  const isOwnListing =
    userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;

  const listingaddress = publicData?.location?.address;
  const addressParts = listingaddress?.split(",");
  const firstThreeParts = addressParts?.slice(0, 2);
  const listinglocation = firstThreeParts?.join(", ");

  const transactionProcessAlias = publicData?.transactionProcessAlias;
  const processName = resolveLatestProcessName(transactionProcessAlias.split('/')[0]);
  const isBooking = isBookingProcess(processName);
  const isPurchase = isPurchaseProcess(processName);
  const processType = isBooking ? ('booking' ? isPurchase : 'purchase') : 'inquiry';

  const currentAuthor = authorAvailable ? currentListing.author : null;
  const ensuredAuthor = ensureUser(currentAuthor);
  const noPayoutDetailsSetWithOwnListing =
    isOwnListing && (processType !== 'inquiry' && !currentUser?.attributes?.stripeConnected);
  const payoutDetailsWarning = noPayoutDetailsSetWithOwnListing ? (
    <span className={css.payoutDetailsWarning}>
      <FormattedMessage id="ListingPage.payoutDetailsWarning" values={{ processType }} />
      <NamedLink name="StripePayoutPage">
        <FormattedMessage id="ListingPage.payoutDetailsWarningLink" />
      </NamedLink>
    </span>
  ) : null;
  const { additionalDetails, propertyItems,brandName  } = publicData 
  const showWaitlistDetail = currentListing?.attributes?.metadata?.email ? currentListing?.attributes?.metadata?.email : null
  const foundEmail = showWaitlistDetail && showWaitlistDetail?.includes(currentuseremail);
  // When user is banned or deleted the listing is also deleted.
  // Because listing can be never showed with banned or deleted user we don't have to provide
  // banned or deleted display names for the function
  const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');

  const { formattedPrice } = priceData(price, config.currency, intl);

  const commonParams = { params, history, routes: routeConfiguration };
  const onContactUser = handleContactUser({
    ...commonParams,
    currentUser,
    callSetInitialValues,
    location,
    setInitialValues,
    setInquiryModalOpen,
  });
  // Note: this is for inquiry state in booking and purchase processes. Inquiry process is handled through handleSubmit.
  const onSubmitInquiry = handleSubmitInquiry({
    ...commonParams,
    getListing,
    onSendInquiry,
    setInquiryModalOpen,
  });
  const onSubmit = handleSubmit({
    ...commonParams,
    currentUser,
    callSetInitialValues,
    getListing,
    onInitializeCardPaymentData,
  });

  const handleOrderSubmit = values => {
    const isCurrentlyClosed = currentListing.attributes.state === LISTING_STATE_CLOSED;
    if (isOwnListing || isCurrentlyClosed) {
      window.scrollTo(0, 0);
    } else {
      onSubmit(values);
    }
  };

  const facebookImages = listingImages(currentListing, 'facebook');
  const twitterImages = listingImages(currentListing, 'twitter');
  const schemaImages = listingImages(
    currentListing,
    `${config.layout.listingImage.variantPrefix}-2x`
  ).map(img => img.url);
  const marketplaceName = config.marketplaceName;
  const schemaTitle = intl.formatMessage(
    { id: 'ListingPage.schemaTitle' },
    { title, price: formattedPrice, marketplaceName }
  );
  // You could add reviews, sku, etc. into page schema
  // Read more about product schema
  // https://developers.google.com/search/docs/advanced/structured-data/product
  const productURL = `${config.marketplaceRootURL}${location.pathname}${location.search}${location.hash}`;
  const schemaPriceMaybe = price
    ? {
      price: intl.formatNumber(convertMoneyToNumber(price), {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      priceCurrency: price.currency,
    }
    : {};
  const currentStock = currentListing.currentStock?.attributes?.quantity || 0;
  const schemaAvailability =
    currentStock > 0 ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock';

  const createFilterOptions = options => options.map(o => ({ key: `${o.option}`, label: o.label }));

  return (
    <Page
      title={schemaTitle}
      scrollingDisabled={scrollingDisabled}
      author={authorDisplayName}
      description={description}
      facebookImages={facebookImages}
      twitterImages={twitterImages}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'Product',
        description: description,
        name: schemaTitle,
        image: schemaImages,
        offers: {
          '@type': 'Offer',
          url: productURL,
          ...schemaPriceMaybe,
          availability: schemaAvailability,
        },
      }}
    >
      <LayoutSingleColumn className={css.pageRoot} topbar={topbar} footer={<FooterContainer />}>
        <div className={css.contentWrapperForProductLayout}>
          <div className={css.mainColumnForProductLayout}>
            {currentListing.id && noPayoutDetailsSetWithOwnListing ? (
              <ActionBarMaybe
                className={css.actionBarForProductLayout}
                isOwnListing={isOwnListing}
                listing={currentListing}
                showNoPayoutDetailsSet={noPayoutDetailsSetWithOwnListing}
              />
            ) : null}
            {currentListing.id ? (
              <ActionBarMaybe
                className={css.actionBarForProductLayout}
                isOwnListing={isOwnListing}
                listing={currentListing}
                listingType={listingType}
                isLocation={isLocation}
                editParams={{
                  id: listingId.uuid,
                  slug: listingSlug,
                  type: listingPathParamType,
                  tab: listingTab,
                }}
              />
            ) : null}
             {
               foundEmail  ? 
              <NamedLink name="SearchPage" className={css.backButton}>
                <span className={css.topbarLinkLabel}>
                  <FormattedMessage id="ListingPage.backToCourse" />
                </span>
                
              </NamedLink>
           :null
            }
            <SectionGallery
              listing={currentListing}
              variantPrefix={config.layout.listingImage.variantPrefix}
            />
            {
              listingType == "product-listing" ?null :<div className={css.mobileHeading}>
              <H4 as="h1" className={css.orderPanelTitle}>
                <FormattedMessage id="ListingPage.orderTitle" values={{ title: richTitle }} />
              </H4>
            </div>
            }
            
            <div className={css.listingLeft}>
              <div  className={listingType === "course-listing" ?css.descriptionSection:null}>
                {listingType === "course-listing"  && <div className={css.descriptionHeading}>Description</div>}
                <SectionTextMaybe text={description} showAsIngress />
              </div>
              {listingType == "product-listing" ?
                <div className={css.productBox}>
                  <div className={css.additionalDetails}>{additionalDetails}</div>
                  <div className={css.detailHeadingText}>Details</div>
                  <div className={css.detailsBox}>
                    {/* <div className={css.detailslist}>
                      <span className={css.detailHeading}>Brand</span>
                      <span className={css.detailValue} >{brandName}</span>
                    </div> */}
                  </div>
                </div> :
                <div className={css.courseBox}>
                  <div className={css.detailHeadingText}>Details</div>
                  <div className={css.detailsBox}>
                    <div className={css.detailslist}>
                      <span className={css.detailHeading}>Dress Code</span>
                      <span className={css.detailValue}>{dressCodeLabels}</span>
                    </div>
                    <div className={css.detailslist}>
                      <span className={css.detailHeading}>Package</span>
                      <span className={css.detailValue}>{title}</span>
                    </div>
                    <div className={css.detailslist}>
                      <span className={css.detailHeading}>Course Levels</span>
                      <span className={css.detailValue}>{courseLevelsLabels}</span>
                    </div>
                    <div className={css.detailslist}>
                      <span className={css.detailHeading}>Where</span>
                      <span className={css.detailValue}>{listinglocation}</span>
                    </div>
                  </div>
                </div>
              }

              {listingType === "course-listing" && listItemsLabels &&
                <div className={css.includeDetailsBox}>
                  <div className={css.includeTextHeading}>What is included :</div>
                  <div className={css.listItemsGrid}>
                    {listItemsLabels && listItemsLabels.length && listItemsLabels.map((item, index) => {
                      return (
                        <div key={index} className={css.includeList}>
                          <span>
                            {item}
                          </span>
                        </div>
                      )
                    })}
                  </div>
                </div>
              }
              {listingType == "product-listing" &&
                <div className={css.propertiesList}>
                  {/* <div className={css.propertiesHeading}>Properties</div> */}
                  {
                    propertyItems && propertyItems.map((item,index) => (
                      <ul key={index}>
                        <li>{item}</li>
                      </ul>
                    ))
                  }
                </div>
              }
              {/* {listingType == "product-listing" &&
                <div className={css.certificationsList}>
                  <div className={css.propertiesHeading}>Certifications Required</div>
                  <ol key={"product-listing"}>
                    <li>This Certification</li>
                    <li> This Certification</li>
                  </ol>
                </div>
              } */}

              {listingType === "course-listing" && FAQsArr && FAQsArr.length > 0 &&
                <div className={css.faqBox}>
                  <div className={css.faqHeading}>FAQ Section</div>
                  {FAQsArr && FAQsArr.length > 0 && FAQsArr.map((item, index) => {
                    return (
                      <details key={index} className={css.accordianBox}>
                        <summary>{item.FAQTitle}</summary>
                        <div className={css.accordianDescription}>{item.FAQPolicies}</div>
                      </details>
                    )
                  })}
                </div>}
              <SectionDetailsMaybe
                publicData={publicData}
                metadata={metadata}
                listingConfig={listingConfig}
                intl={intl}
              />
              {listingConfig.listingFields.reduce((pickedElements, config) => {
                const { key, enumOptions, includeForListingTypes, scope = 'public' } = config;
                const listingType = publicData?.listingType;
                const isTargetListingType =
                  includeForListingTypes == null || includeForListingTypes.includes(listingType);

                const value =
                  scope === 'public' ? publicData[key] : scope === 'metadata' ? metadata[key] : null;
                const hasValue = value != null;
                return isTargetListingType && config.schemaType === SCHEMA_TYPE_MULTI_ENUM
                  ? [
                    ...pickedElements,
                    <SectionMultiEnumMaybe
                      key={key}
                      heading={config?.showConfig?.label}
                      options={createFilterOptions(enumOptions)}
                      selectedOptions={value || []}
                    />,
                  ]
                  : isTargetListingType && hasValue && config.schemaType === SCHEMA_TYPE_TEXT
                    ? [
                      ...pickedElements,
                      <SectionTextMaybe key={key} heading={config?.showConfig?.label} text={value} />,
                    ]
                    : pickedElements;
              }, [])}

              <SectionReviews reviews={reviews} listingType={listingType} fetchReviewsError={fetchReviewsError} />
              <SectionAuthorMaybe
                title={title}
                listingType={listingType}
                listing={currentListing}
                authorDisplayName={authorDisplayName}
                onContactUser={onContactUser}
                isInquiryModalOpen={isAuthenticated && inquiryModalOpen}
                onCloseInquiryModal={() => setInquiryModalOpen(false)}
                sendInquiryError={sendInquiryError}
                sendInquiryInProgress={sendInquiryInProgress}
                onSubmitInquiry={onSubmitInquiry}
                currentUser={currentUser}
                onManageDisableScrolling={onManageDisableScrolling}
              />
            </div>
          </div>

          <div className={css.orderColumnForProductLayout}>
            {
              listingType === "course-listing" &&
              <div className={css.buttonWrapper}>
              <button
                className={css.emailButton}
                onClick={() => handleClickEmailInquiry(isAuthenticated)}
              >
                <img src={emailInquiryIcon} />
                Email Inquiry
              </button>
              <button
                className={css.shareButton}
                onClick={() => setIsOpenSocialModel(true)}
              >
                <IconCard brand="share" />
                Share
              </button>
            </div>

            }
           
            <Modal
              isOpen={modalIsOpen}
              id={"emailEnquiryForm"}
              buttonclose={true}
              onClose={closeModal}
              onManageDisableScrolling={onManageDisableScrolling}
            >
              <div className={css.modalContent}>
                <EmailInquiryForm
                  className={css.inquiryForm}
                  submitButtonWrapperClassName={css.inquirySubmitButtonWrapper}
                  listingTitle={title}
                  authorDisplayName={authorDisplayName}
                  sendInquiryError={sendInquiryError}
                  onSubmit={(values) => closeModal(values)}
                  inProgress={sendInquiryInProgress}
                />
              </div>
            </Modal>
            <SocialShare
              isOpen={isOpenSocialModel}
              url={PageUrl}
              onClose={() => setIsOpenSocialModel(false)}
              onManageDisableScrolling={onManageDisableScrolling}
            />
            <OrderPanel
              className={css.productOrderPanel}
              totalTimeSlots={totalTimeSlots}
              listing={currentListing}
              listinglocation={listinglocation}
              isOwnListing={isOwnListing}
              isAuthenticated={isAuthenticated}
              currentUser={currentUser}
              onSubmit={handleOrderSubmit}
              currentuseremail={currentuseremail}
              authorLink={
                <NamedLink
                  className={css.authorNameLink}
                  name="ListingPage"
                  params={params}
                  to={{ hash: '#author' }}
                >
                  {authorDisplayName}
                </NamedLink>
              }
              title={<FormattedMessage id="ListingPage.orderTitle" values={{ title: richTitle }} />}
              titleDesktop={
                <H4 as="h1" className={css.orderPanelTitle}>
                  <FormattedMessage id="ListingPage.orderTitle" values={{ title: richTitle }} />
                </H4>
              }
              onshowListing={onshowListing}
              config={config}
              onwaitlistSendEmail={onwaitlistSendEmail}
              payoutDetailsWarning={payoutDetailsWarning}
              author={ensuredAuthor}
              onManageDisableScrolling={onManageDisableScrolling}
              onContactUser={onContactUser}
              monthlyTimeSlots={monthlyTimeSlots}
              onFetchTimeSlots={onFetchTimeSlots}
              onFetchTransactionLineItems={onFetchTransactionLineItems}
              lineItems={lineItems}
              fetchLineItemsInProgress={fetchLineItemsInProgress}
              fetchLineItemsError={fetchLineItemsError}
              validListingTypes={config.listing.listingTypes}
              marketplaceCurrency={config.currency}
              dayCountAvailableForBooking={config.stripe.dayCountAvailableForBooking}
              marketplaceName={config.marketplaceName}
            />
            {listingType === "course-listing" &&
              <div className={css.locationBox}>
                <SectionMapMaybe
                  geolocation={geolocation}
                  publicData={publicData}
                  listingId={currentListing.id}
                  mapsConfig={config.maps}
                />
                <div className={css.mapLocation}>
                  The exact course location will be provided at checkout.
                </div>
              </div>
            }
          </div>
        </div>
      </LayoutSingleColumn>
    </Page>
  );
};

ListingPageComponent.defaultProps = {
  currentUser: null,
  inquiryModalOpenForListingId: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  monthlyTimeSlots: null,
  sendInquiryError: null,
  lineItems: null,
  fetchLineItemsError: null,
};

ListingPageComponent.propTypes = {
  // from useHistory
  history: shape({
    push: func.isRequired,
  }).isRequired,
  // from useLocation
  location: shape({
    search: string,
  }).isRequired,

  // from useIntl
  intl: intlShape.isRequired,

  // from useConfiguration
  config: object.isRequired,
  // from useRouteConfiguration
  routeConfiguration: arrayOf(propTypes.route).isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,

  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  inquiryModalOpenForListingId: string,
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
  reviews: arrayOf(propTypes.review),
  fetchReviewsError: propTypes.error,
  monthlyTimeSlots: object,
  // monthlyTimeSlots could be something like:
  // monthlyTimeSlots: {
  //   '2019-11': {
  //     timeSlots: [],
  //     fetchTimeSlotsInProgress: false,
  //     fetchTimeSlotsError: null,
  //   }
  // }
  sendInquiryInProgress: bool.isRequired,
  sendInquiryError: propTypes.error,
  onSendInquiry: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
};

const EnhancedListingPage = props => {
  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();

  return (
    <ListingPageComponent
      config={config}
      routeConfiguration={routeConfiguration}
      intl={intl}
      history={history}
      location={location}
      {...props}
    />
  );
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.auth;
  const {
    showListingError,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    sendInquiryInProgress,
    sendInquiryError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    inquiryModalOpenForListingId,
    totalTimeSlots
  } = state.ListingPage;
  const { currentUser } = state.user;
  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    inquiryModalOpenForListingId,
    showListingError,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    sendInquiryInProgress,
    sendInquiryError,
    totalTimeSlots
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onFetchTransactionLineItems: params => dispatch(fetchTransactionLineItems(params)),
  onSendInquiry: (listing, message) => dispatch(sendInquiry(listing, message)),
  onwaitlistSendEmail: ({ email, listingId, showWaitlistDetail }) => dispatch(waitlistSendEmail({ email, listingId, showWaitlistDetail })),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  onFetchTimeSlots: (listingId, start, end, timeZone) =>
    dispatch(fetchTimeSlots(listingId, start, end, timeZone)),

    onshowListing: (listingId, config ) => dispatch(showListing(listingId, config)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(EnhancedListingPage);

export default ListingPage;
